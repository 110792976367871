



























































































































































































































































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import { StudentController, ParentController, LoginController } from '@/services/request.service'
// import AccountEditor from './AccountEditor.vue'
import VueCropper from 'vue-cropperjs'
import FlexTooltip from '@/components/FlexTooltip.vue'

import EditAccountModal from './components/EditAccountModal.vue'
import DetailModal from './components/DetailModal.vue'
import debounce from 'lodash/debounce'
import SetMailModal from './components/SetMailModal.vue'
import 'cropperjs/dist/cropper.css'
// import { fileUploader } from '@/services/qiniu.service'

@Component({
  components: {
    VueCropper,
    // AccountEditor,
    FlexTooltip,
    SetMailModal,
    EditAccountModal,
    DetailModal,
  },
})
export default class Personal extends Vue {
  private isMajor: boolean = false
  private timer: any = null
  private verCodeTimer = 0
  private verCodeLoading: boolean = false
  private parentInfo: any = {}
  private saveLoadingMobile: boolean = false
  private visibleMobile: boolean = false
  private personalInfoForm: any
  private data: any = []
  private personalInfo: any = {}
  private loading: boolean = false
  private editorVisible: boolean = false
  private editVis: boolean = false
  private detailVis: any = false
  private editorType: string = ''
  private addParentFlag = true
  private mode = 'view'
  private saveLoadingSecret: boolean = false
  private saveInfoLoading: boolean = false
  private passwordForm: any
  private mobileForm: any
  private visibleMail: boolean = false
  private visibleSecret: boolean = false
  private disableGetVerCode: boolean = false
  private viewType: string = 'add'
  private currentParentId: any = 0

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('personal.parentName'),
        scopedSlots: { customRender: 'name' },
        dataIndex: 'name',
        align: 'left',
      },
      {
        dataIndex: 'mobile',
        key: 'mobile',
        title: this.$t('personal.mobile'),
        align: 'left',
      },
      {
        dataIndex: 'email',
        key: 'email',
        title: this.$t('personal.email'),
        align: 'left',
      },
      {
        key: 'studentName',
        title: this.$t('personal.studentName'),
        align: 'left',
        scopedSlots: { customRender: 'studentName' },
        ellipsis: true,
      },
      // {
      //   key: 'relationship',
      //   title: this.$t('personal.relation'),
      //   align: 'left',
      //   scopedSlots: { customRender: 'relation' },
      // },
      {
        key: 'operations',
        align: 'left',
        slots: { title: 'tipstest' },
        scopedSlots: { customRender: 'operations' },
        width: 100,
      },
    ]
  }

  private beforeCreate(): void {
    this.personalInfoForm = this.$form.createForm(this)
    this.passwordForm = this.$form.createForm(this)
    this.mobileForm = this.$form.createForm(this)
  }

  private validateOldMobile(rule, value, callback): void {
    if (value !== this.personalInfo.mobile) {
      this.disableGetVerCode = true
      callback(this.$t('personal.oldMobileNotRight'))
    } else {
      this.disableGetVerCode = false
      callback()
    }
  }

  private created(): void {
    this.$nextTick(() => {
      this.getData()
    })
  }

  private disableRecord(parentId): void {
    ParentController.disable({ parentId })
      .then(res => {
        this.$message.success(this.$tc('personal.disableSuccess'))
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private enableRecord(parentId): void {
    ParentController.enable({ parentId })
      .then(res => {
        this.$message.success(this.$tc('personal.enableSuccess'))
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private resendCountDown(): void {
    this.timer = setInterval(() => {
      if (this.verCodeTimer === 0) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.verCodeTimer = this.verCodeTimer - 1
      }
    }, 1000)
  }

  private getVerCode(): void {
    const form = this.mobileForm
    const newMobile = form.getFieldValue('newMobile')
    if (newMobile) {
      LoginController.sendVerifyCodeNoCheck(newMobile)
        .then(res => {
          if (res.data.success) {
            this.$message.success(this.$tc('common.sendVerCodeSuccess'))
            this.verCodeTimer = 60
            this.resendCountDown()
            const mobile = form.getFieldValue('newMobile')
            form.setFields({
              ['newMobile']: {
                value: mobile,
              },
            })
          } else {
            const mobile = form.getFieldValue('newMobile')
            form.setFields({
              ['newMobile']: {
                value: mobile,
                errors: [{ message: res.data.resMsg, field: 'newMobile' }],
              },
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      form.setFields({
        ['newMobile']: {
          value: newMobile,
          errors: [{ message: this.$t('login.enterMobile'), field: 'newMobile' }],
        },
      })
    }
  }

  private validateName(rule, value, callback): void {
    const form = this.personalInfoForm
    const name = form.getFieldValue('name')
    const enName = form.getFieldValue('enName')
    if (enName || name) {
      callback()
    } else {
      callback(this.$t('personal.nameValidateTip'))
    }
  }

  private validatePhone = debounce(e => {
    const form = this.mobileForm
    const mobile = form.getFieldValue('newMobile')
    const areaCode = form.getFieldValue('newAreaCode')
    // ParentController.getParentByMobile(areaCode, mobile)
    //   .then(res => {
    //     if (res.data === null) {
    //       return
    //     } else {
    //       this.$message.error(this.$tc('personal.isExistedMobile'))
    //     }
    //   })
    //   .catch(err => {
    //     console.error(err)
    //   })
  }, 1000)

  private addParent(): void {
    this.editorType = 'addParentAccount'
    this.editorVisible = true
    this.parentInfo = {}
  }

  private editTableDta(parent): void {
    this.editorType = 'editParentAccount'
    this.parentInfo = parent
    this.editorVisible = true
  }

  private edit(): void {
    this.mode = 'edit'
    this.$nextTick(() => {
      this.personalInfoForm.setFieldsValue(this.personalInfo)
    })
  }

  private modifyPassword(): void {
    this.saveLoadingSecret = true
    const form = this.passwordForm
    form.validateFields((err, values) => {
      if (err) {
        this.saveLoadingSecret = false
        return
      } else {
        const request = {} as any
        request.oldPassword = values.password
        request.newPassword = values.newPassword
        ParentController.updatePassword(request)
          .then(res => {
            if (!res.data.success) {
              form.setFields({
                ['password']: {
                  errors: [{ message: this.$t('personal.passwordLimit'), field: 'password' }],
                },
              })
            } else {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.visibleSecret = false
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoadingSecret = false
          })
      }
    })
  }

  private saveMobile(): void {
    this.saveLoadingMobile = true
    const form = this.mobileForm
    form.validateFields((err, values) => {
      if (err) {
        this.saveLoadingMobile = false
        return
      } else {
        ParentController.updateMobile(values)
          .then(res => {
            if (!res.data.success) {
              this.$message.error(res.data.resMsg)
            } else {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.saveLoadingMobile = false
              this.cancelMobile()
              this.getData()
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoadingMobile = false
          })
      }
    })
  }

  private editCancel(): void {
    this.mode = 'view'
  }

  private saveInfo(): void {
    this.saveInfoLoading = true
    const form = this.personalInfoForm
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveInfoLoading = false
        return
      } else {
        values.parentId = this.personalInfo.parentId
        ParentController.updateUserInfo(values)
          .then(res => {
            this.saveInfoLoading = false
            this.$message.success(this.$tc('common.saveSuccess'))
            this.getData()
            this.mode = 'view'
          })
          .catch(err => {
            console.error(err)
          })
        this.saveInfoLoading = false
      }
    })
  }

  private cancel(): void {
    this.visibleSecret = false
  }

  private cancelMobile(): void {
    this.visibleMobile = false
  }

  private modifySecret(): void {
    this.visibleSecret = true
  }

  private modifyMobile(): void {
    this.visibleMobile = true
  }

  private modifyMail(): void {
    this.visibleMail = true
  }

  private getUserInfo(): void {
    ParentController.getUserInfo()
      .then(res => {
        this.personalInfo = res.data
        this.isMajor = res.data.isMajor
        this.personalInfo.mobileView =
          this.personalInfo.mobile.substring(0, 3) + '****' + this.personalInfo.mobile.substring(7)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getRelatedParents(): void {
    ParentController.getRelatedParents()
      .then(res => {
        this.data = res.data
        if (this.data.length >= 5) {
          this.addParentFlag = false
        } else {
          this.addParentFlag = true
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    this.getUserInfo()
    this.getRelatedParents()
  }

  public validateToNextPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/.test(value)) {
      callback()
    } else {
      callback(this.$t('personal.passwordUnLaw'))
    }
  }

  public compareToFirstPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(this.$t('personal.wrongPassword'))
    } else {
      callback()
    }
  }

  private editAccount(type = 'add', parentId?: number): void {
    this.viewType = type
    this.currentParentId = parentId
    this.editVis = true
  }

  private viewDetail(parentId): void {
    this.currentParentId = parentId
    this.detailVis = true
  }
}
