var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"set-mail-modal",attrs:{"width":600,"visible":_vm.visible,"title":_vm.$t('personal.modifyEmail'),"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":"","destroyOnClose":""},on:{"cancel":_vm.cancel,"ok":_vm.saveEmail}},[_c('a-form',{attrs:{"form":_vm.mailForm}},[_c('a-form-item',{attrs:{"label":this.$t('login.password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterPassword'),
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterPassword'),\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('login.password')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('common.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newEmail',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterMail'),
              } ],
          } ]),expression:"[\n          'newEmail',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterMail'),\n              },\n            ],\n          },\n        ]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":_vm.$t('login.enterMail')}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'vcode',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('login.enterVerCode'),
              } ],
          } ]),expression:"[\n          'vcode',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('login.enterVerCode'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"75%"},attrs:{"placeholder":this.$t('common.enter')}}),_c('a-button',{staticStyle:{"width":"24%","margin-left":"1%"},attrs:{"loading":_vm.verCodeLoading,"disabled":_vm.verCodeTimer > 0},on:{"click":_vm.getVerCode}},[_vm._v(" "+_vm._s(_vm.verCodeTimer ? _vm.$t('login.resendVerCode', _vm.verCodeTimer, { time: _vm.verCodeTimer }) : _vm.$t('login.getVerCode'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }