





















































































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import { LoginController, ParentController } from '@/services/request.service'

@Component
export default class SetMailModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly type!: any
  @Prop() private readonly data!: any

  private mailForm: any
  private resetTimer: any = null
  private verCodeLoading = false
  private verCodeTimer = 0
  private saveLoading = false

  private created(): void {
    this.mailForm = this.$form.createForm(this)
  }

  private validateResetPwd(rule, value, cb) {
    const form = this.mailForm
    const exp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/
    if (exp.test(value)) {
      if (value) {
        form.validateFields(['repeatPassword'], { force: true })
      }
    } else {
      cb(this.$t('login.invalidPwd'))
    }
    cb()
  }

  private getVerCode(): void {
    const form = this.mailForm
    const email = form.getFieldValue('newEmail')
    if (email) {
      this.verCodeLoading = true
      LoginController.sendVerifyToMailCodeNoCheck(email)
        .then(res => {
          if (res.data.success) {
            this.$message.success(this.$tc('common.sendVerCodeSuccess'))
            this.verCodeTimer = 60
            this.resetResendCountDown()
            const email = form.getFieldValue('newEmail')
          } else {
            const email = form.getFieldValue('newEmail')
            form.setFields({
              ['newEmail']: {
                value: email,
                errors: [{ message: res.data.resMsg, field: 'newEmail' }],
              },
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.verCodeLoading = false))
    } else {
      form.setFields({
        ['newEmail']: {
          value: email,
          errors: [{ message: this.$t('login.enterMail'), field: 'newEmail' }],
        },
      })
    }
  }

  private resetResendCountDown(): void {
    this.resetTimer = setInterval(() => {
      if (this.verCodeTimer === 0) {
        clearInterval(this.resetTimer)
        this.resetTimer = null
      } else {
        this.verCodeTimer = this.verCodeTimer - 1
      }
    }, 1000)
  }

  private saveEmail(): void {
    this.saveLoading = true
    const form = this.mailForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        let condition = {
          ...values,
        }
        ParentController.updateEmail(condition)
          .then(res => {
            if (res.data.success) {
              this.$message.success(this.$tc('tips.setEmailSuccess'))
              this.$emit('success')
              this.closeModal()
            } else {
              const email = form.getFieldValue('newEmail')
              form.setFields({
                ['newEmail']: {
                  value: email,
                  errors: [{ message: res.data.resMsg, field: 'newEmail' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoading = false
          })
      } else {
        this.saveLoading = false
        return
      }
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }
}
