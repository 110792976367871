























































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import { ParentController, DropDownController, StudentController } from '@/services/request.service'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class DetailModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly currentParentId: any

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  private countryList: Array<any> = []
  private students: Array<any> = []
  private data: any = {}

  private groupTransferVisible = false
  private transferTitle = ''
  private transferCandidate: Array<any> = []
  private transferSelected: Array<any> = []
  private selectedStudents: Array<any> = []
  private formItemLayout: any = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  private studentList: Array<any> = []
  private roleList: Array<any> = [
    'father',
    'mother',
    'patGrandPa',
    'patGrandMa',
    'matGrandPa',
    'matGrandMa',
    'other',
  ]

  private accountEditor: any = {
    name: undefined,
    enName: undefined,
    mobile: undefined,
    email: undefined,
    countryId: undefined,
    workUnit: undefined,
    position: undefined,
    graduatedFrom: undefined,
    wxid: undefined,
    highestQualificationName: undefined,
    highestQualificationEnName: undefined,
    students: [],
  }
  private get locale(): any {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get countryName(): any {
    const country = this.countryList.find(item => item.key === this.accountEditor.countryId)
    return country ? (this.locale === 'zh' ? country.value : country.enValue) : ''
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible): void {
    if (visible) {
      this.getDropDownInfo()
      this.getParentDetail()
    }
  }

  private getParentDetail(): any {
    ParentController.detail(this.currentParentId)
      .then(res => {
        this.accountEditor = res.data
      })
      .catch(err => console.log(err))
  }

  private getDropDownInfo(): void {
    Promise.all([DropDownController.getCountryList()])
      .then(res => {
        this.countryList = res[0].data
      })
      .catch(err => console.log(err))
  }
}
