



















































































































































































































































































import { ParentController, DropDownController, StudentController } from '@/services/request.service'
import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { getSchoolInfo } from '@/utils/utils'

const operations = {
  add: function(condition) {
    return ParentController.add(condition)
  },
  edit: function(condition) {
    return ParentController.update(condition)
  },
}

@Component({})
export default class EditPeriodModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly viewType: any
  @Prop() private readonly currentParentId: any

  private countryList: Array<any> = []
  private qualificationList: Array<any> = []

  private dataLoading: any = true
  private groupTransferVisible = false
  private transferTitle = ''
  private transferCandidate: Array<any> = []
  private transferSelected: Array<any> = []
  private selectedStudents: Array<any> = []
  private formItemLayout: any = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  private studentList: Array<any> = []
  private roleList: Array<any> = [
    'father',
    'mother',
    'patGrandPa',
    'patGrandMa',
    'matGrandPa',
    'matGrandMa',
    'other',
  ]

  private accountEditor: any = {
    name: undefined,
    enName: undefined,
    mobile: undefined,
    email: undefined,
    countryId: undefined,
    workUnit: undefined,
    position: undefined,
    graduatedFrom: undefined,
    wxid: undefined,
    highestQualification: undefined,
    students: [],
  }
  private saveLoading: any = false

  //自定义范围校验
  private scopeErr: boolean = false
  private scopeErrHelp: string = ''

  private get locale(): any {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get cantDeleteLIst(): any {
    return this.accountEditor.students.filter(item => !item.canDelete)
  }

  private get cantDeleteIdLIst(): any {
    return this.accountEditor.students.filter(item => !item.canDelete).map(item => item.id)
  }

  private get isAllAssociated(): boolean {
    const studentIdList = this.studentList.map(item => item.studentId)
    return (
      this.accountEditor.students.filter(
        item =>
          !item.studentId || (studentIdList.includes(item.studentId) && item.status !== '1016')
      ).length >= this.studentList.length
    )
    // return false
  }

  private getRealLabel(title, status): any {
    let result = status === '1016' ? title + `(${this.$t('staticCode.1016')})` : title
    return result
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible): void {
    if (visible) {
      this.getDropDownInfo()
      if (this.currentParentId) {
        this.getParentDetail()
      }
    } else {
      // (this.$refs['accountForm'] as any).resetFields()
      this.accountEditor = {
        name: undefined,
        enName: undefined,
        mobile: undefined,
        email: undefined,
        countryId: undefined,
        workUnit: undefined,
        position: undefined,
        graduatedFrom: undefined,
        wxid: undefined,
        highestQualification: undefined,
        students: [],
      }
    }
  }

  private getParentDetail(): any {
    ParentController.detail(this.currentParentId)
      .then(res => {
        this.accountEditor = res.data
      })
      .catch(err => console.log(err))
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getCountryList(),
      // StudentController.getStudentsGroupBySection(),
      StudentController.getStudentList(true),
      DropDownController.getQualificationList(),
    ])
      .then(res => {
        this.countryList = res[0].data
        this.studentList = res[1].data
        this.qualificationList = res[2].data
      })
      .catch(err => console.log(err))
      .finally(() => (this.dataLoading = false))
  }

  private validatePhoneBeforeSend(rule, value, cb): void {
    if (value) {
      ParentController.verifyMobile(
        value,
        this.viewType === 'edit' ? this.currentParentId : undefined
      )
        .then(res => {
          if (res.data) {
            cb()
          } else {
            cb(this.$t('personal.duplicatedMobile'))
          }
        })
        .catch(err => {
          console.log(err)
          cb()
        })
    } else {
      cb(this.$t('parentAccount.enterAccount'))
    }
  }

  private validateEmailBeforeSend(rule, value, cb): void {
    const regExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (value) {
      if (regExp.test(value)) {
        ParentController.verifyEmail(
          value,
          this.viewType === 'edit' ? this.currentParentId : undefined
        )
          .then(res => {
            if (res.data) {
              cb()
            } else {
              cb(this.$t('personal.duplicatedEmail'))
            }
          })
          .catch(err => {
            console.log(err)
            cb()
          })
      } else {
        cb(this.$t('addressBook.enterlegalEmail'))
      }
    } else {
      cb(this.$t('addressBook.enterEmail'))
    }
  }

  private validateName(rule, value, callback): void {
    const name = this.accountEditor.name
    const enName = this.accountEditor.enName
    if (enName || name) {
      callback()
    } else {
      callback(this.$t('personal.nameValidateTip'))
    }
  }

  private validateRelation(rule, value, callback): void {
    if (!rule.studentId) {
      // callback(this.$t('parentAccount.selectStudent'))
      callback()
      return
    }
    const student = this.accountEditor.students.find(item => item.studentId === rule.studentId)
    if (student.relationship && student.relationship.length) {
      StudentController.getParents(rule.studentId).then(res => {
        let relations = res.data.map(item => item.relationship)
        let relation = student.relationship
        if (
          relations.includes(relation) &&
          relation !== 'other' &&
          res.data.find(item => item.parentId === this.currentParentId)?.relationship !== relation
        ) {
          callback(this.$t('personal.duplicatedRelations'))
        } else {
          callback()
        }
      })
    } else {
      callback(this.$t('personal.selectRelation'))
    }
  }

  private validateStudent(rule, value, callback): void {
    if (!rule.studentId) {
      callback(this.$t('parentAccount.selectStudent'))
    } else {
      callback()
    }
  }

  private addStudent(): void {
    this.accountEditor.students.push({
      canDelete: true,
      studentId: undefined,
      relationship: undefined,
    })
  }

  private onNameChange(value, type): void {
    let other: any
    if (!value) return
    switch (type) {
      case 'name':
        other = 'enName'
        break
      case 'enName':
        other = 'name'
        break
    }
    this.$nextTick(() => {
      ;(this.$refs['accountForm'] as any).validateField(other, err => {
        return
      })
    })
  }

  private validateBeforeSend(): void {
    if (!this.accountEditor.students.length) {
      this.scopeErr = true
      this.scopeErrHelp = this.$tc('parentAccount.soleParent')
      return
    } else {
      this.scopeErr = false
      this.scopeErrHelp = ''
      this.saveParent()
    }
  }

  private deleteStudent(index): void {
    this.accountEditor.students.splice(index, 1)
  }

  private saveParent(): void {
    ;(this.$refs['accountForm'] as any).validate(valid => {
      if (valid) {
        this.saveLoading = true
        const {
          // name,
          // enName,
          // mobile,
          // email,
          // countryId,
          // workUnit,
          // position,
          students,
          ...items
        } = this.accountEditor
        const condition = {
          parentId: this.viewType === 'edit' ? this.currentParentId : undefined,
          ...this.accountEditor,
        }
        operations[this.viewType](condition)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$emit('update')

            this.closeModal()
          })
          .catch(err => console.log(err))
          .finally(() => (this.saveLoading = false))
      } else {
        return false
      }
    })
  }
}
